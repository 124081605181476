import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/YMqhVOTii8Q">
    <SEO title="The Issue of Identity - Touching Holiness" />
  </Layout>
)

export default SermonPost
